import React, { Component } from 'react'
import Link from 'gatsby-link'
// import Img from 'gatsby-image'

export default class PortfolioCard extends Component {
  render() {
    return (
      <div className="column is-4">
        <div className="card">
          <div className="card-image">
            <Link to="#">
              <figure className="image is-2by1">
                <img src={this.props.portfolioImage} alt="Placeholder" />
              </figure>
            </Link>
          </div>
          <div className="card-content">
            <h4>{this.props.portfolioTitle}</h4>
            <div className="content">{this.props.portfolioDescription}</div>
          </div>
        </div>
      </div>
    )
  }
}
