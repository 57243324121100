import React, { Component } from 'react'
import PortfolioCard from '../components/portfolio/portfolioCard'
import LogoLight from '../img/logo-dark.png'
import Layout from '../layouts/index'
import PortfolioData from '../data/portfolio'
import { graphql } from 'gatsby'

class Portfolio extends Component {
  state = {
    portfolios: PortfolioData.portfolio,
  }

  renderPortfolio = this.state.portfolios.map(portfolio => {
    return (
      <PortfolioCard
        portfolioTitle={portfolio.title}
        portfolioDescription={portfolio.description}
        portfolioImage={portfolio.image}
      />
    )
  })

  render() {
    const { portfolio } = this.props.data.allDataJson.edges[0].node

    return (
      <Layout>
        <div className="container">
          <section className="section section-title">
            <div className="container">
              <h1 className="title has-text-centered">
                New Portfolio Coming Soon
              </h1>
            </div>
            <hr className="section-title__gap" />
          </section>

          {/* <section className="section portfolio-content">
            <div className="container">
              <div className="columns features is-multiline">
                <PortfolioCard
                  portfolioFilter="Print"
                  portfolioTitle="Get Lost in Thailand"
                  portfolioDescription="Print Marketing"
                  portfolioImage={LogoLight}
                />
                <PortfolioCard
                  portfolioFilter="Print"
                  portfolioTitle="Get Lost in Thailand"
                  portfolioDescription="Print Marketing"
                  portfolioImage={LogoLight}
                />
                <PortfolioCard
                  portfolioFilter="Print"
                  portfolioTitle="Get Lost in Thailand"
                  portfolioDescription="Print Marketing"
                  portfolioImage={LogoLight}
                />
                <PortfolioCard
                  portfolioFilter="Print"
                  portfolioTitle="Get Lost in Thailand"
                  portfolioDescription="Print Marketing"
                  portfolioImage={LogoLight}
                />
                <PortfolioCard
                  portfolioFilter="Print"
                  portfolioTitle="Get Lost in Thailand"
                  portfolioDescription="Print Marketing"
                  portfolioImage={LogoLight}
                />

                {portfolio.map(port => (
                  <PortfolioCard
                    portfolioTitle={port.title}
                    portfolioDescription={port.description}
                    portfolioImage={port.image}
                  />
                ))}
              </div>
            </div>
          </section> */}
        </div>
      </Layout>
    )
  }
}

export const query = graphql`
  query Portfolio {
    allDataJson {
      edges {
        node {
          portfolio {
            title
            category
            description
            image {
              name
              src
            }
          }
        }
      }
    }
  }
`

export default Portfolio
